import UserActionsTypes from './user.types';


const INITIAL_STATE = {
    currentUser: null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case UserActionsTypes.SET_CURRENT_USER:
            if (action.payload) {
                localStorage.setItem('user', JSON.stringify(action.payload));
            } else {
                localStorage.removeItem('user');
            }
            return {
                ...state, currentUser: action.payload
            };
        default:
            return state;
    }
}

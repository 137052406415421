import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import getVilles from "../../../redux/villes/villes.selectors";
import { deleteVille, setAllVille } from "../../../redux/villes/villes.actions";
import "./villes-list.styles.scss";
import Loader from "../../../components/header/loader/loader.component";

import ImmoApi from "../../../Api";
import config from "../../../config";

class ListVilles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  handleDelete = (id) => {
    const isConfirmed = window.confirm("voulez vous vraiment supprimer ?");
    if (isConfirmed) {
      this.deleteVille(id);
    }
  };

  componentDidMount() {
    this.loadVilles();
  }

  loadVilles = () => {
    ImmoApi.get(config.BACKEND_URL + "/api/villes")
      .then((res) => {
        console.log(res.data);
        this.props.setAllVille(res.data);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  deleteVille(id) {
    ImmoApi.delete(config.BACKEND_URL + `/api/villes/${id}`)
      .then((res) => {
        this.props.deleteVille(id);
      })
      .catch((err) => {
        this.setState({ error: err.message });
      });
  }

  render() {
    const { villes, history, match } = this.props;
    return (
      <div className="villes-list">
        <div className="action-bar">
          <button
            className="button "
            onClick={() => history.push(match.url + "/ajouter")}
          >
            Ajouter <i className="fa fa-add"></i>
          </button>
        </div>
        {this.props.loading ? (
          <Loader />
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Nom</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {villes.map((ville) => (
                <tr key={ville.id}>
                  <td>{ville.id}</td>
                  <td>{ville.name}</td>
                  <td>
                    <div className="actions-btn">
                      <button
                        className="btn btn-primary "
                        onClick={() =>
                          history.push(match.url + `/${ville.id}/editer`)
                        }
                      >
                        Editer <i className="fa fa-add"></i>
                      </button>

                      <button
                        className="btn btn-small btn-danger "
                        onClick={() => this.handleDelete(ville.id)}
                      >
                        Supprimer <i className="fa fa-add"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    villes: getVilles(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteVille: (id) => dispatch(deleteVille(id)),
    setAllVille: (id) => dispatch(setAllVille(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListVilles));

import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import getBiens from "../../../redux/biens/biens.selectors";

const EditerBien = ({ bien }) => {
  const [titre, setTitre] = useState(bien.titre);
  const [description, setDescription] = useState(bien.description);
  return (
    <div className="ajouter-bien">
      <form>
        <div className="form-group">
          <label htmlFor="titre">Titre</label>
          <input
            type="text"
            name="titre"
            className="form-control"
            id="titre"
            required
            onChange={(e) => setTitre(e.target.value)}
            value={titre}
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            className="form-control"
            id="description"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div>
          <button className="btn btn-primary">ANNULER</button>
          <button className="btn btn-success">ENREGISTER</button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const bien = getBiens(state).filter(
    (b) => b.id === parseInt(ownProps.match.params.id)
  )[0];
  return {
    bien: bien,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(withRouter(EditerBien));

import {
    ADD_BIEN,
    SET_ALL_BIENS
} from "./biens.types"


export const setAllBiens = (biens) => ({
    type: SET_ALL_BIENS,
    payload: biens
})


export const addBien = (bien) => ({
    type: ADD_BIEN,
    payload: bien
})

import React from "react";
import Loader from "../../../components/header/loader/loader.component";

import ImmoApi from "../../../Api";
import config from "../../../config";

import "./categories-biens.styles.scss";
import { withRouter } from "react-router-dom";

class ListCategories extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      categories: [],
    };
  }
  handleDelete = (id) => {
    const isConfirmed = window.confirm("voulez vous vraiment supprimer ?");
    if (isConfirmed) {
      this.deleteVille(id);
    }
  };

  deleteVille(id) {
    ImmoApi.delete(config.BACKEND_URL + `/api/types/${id}`)
      .then((res) => {
        console.log(res);
        this.setState({
          categories: this.state.categories.filter((c) => c.id !== id),
        });
      })
      .catch((err) => {
        this.setState({ error: err.message });
      });
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    ImmoApi.get(config.BACKEND_URL + "/api/types")
      .then((res) => {
        console.log(res.data);
        this.setState({ loading: false, categories: res.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  render() {
    const { history, match } = this.props;
    return (
      <div className="villes-list">
        <div className="action-bar">
          <button
            className="button "
            onClick={() => history.push(match.url + "/ajouter")}
          >
            Ajouter <i className="fa fa-add"></i>
          </button>
        </div>
        {this.props.loading ? (
          <Loader />
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Nom</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.categories.map((cat) => (
                <tr key={cat.id}>
                  <td>{cat.id}</td>
                  <td>{cat.name}</td>
                  <td>
                    <div className="actions-btn">
                      <button
                        className="btn btn-primary "
                        onClick={() =>
                          history.push(match.url + `/${cat.id}/editer`)
                        }
                      >
                        Editer <i className="fa fa-add"></i>
                      </button>

                      <button
                        className="btn btn-small btn-danger "
                        onClick={() => this.handleDelete(cat.id)}
                      >
                        Supprimer <i className="fa fa-add"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default withRouter(ListCategories);

import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import ImmoApi from "../../../Api";
import config from "../../../config";

const AjouterCat = () => {
  const [name, setName] = useState("");
  const { addToast } = useToasts();

  const handleChange = (event) => {
    const { value } = event.target;
    console.log(event.target.value);
    setName(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addCat({ name: name });
  };

  const addCat = (data) => {
    console.log(data);

    ImmoApi.post(config.BACKEND_URL + "/api/types", data)
      .then((res) => {
        setName("");
        addToast("Sauvegarde effectue", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="ajouter-ville">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nom</label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            required
            value={name}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <button>Ajouter</button>
        </div>
      </form>
    </div>
  );
};

export default AjouterCat;

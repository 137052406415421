import React, { useState } from "react";

import { useToasts } from "react-toast-notifications";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { updateVille } from "../../../redux/villes/villes.actions";

import getVilles from "../../../redux/villes/villes.selectors";
import ImmoApi from "../../../Api";
import config from "../../../config";
import history from "../../../utils/history";

const EditerVille = ({ ville, updateVille }) => {
  const { addToast } = useToasts();
  const saveUpdate = (e, id, data) => {
    e.preventDefault();
    ImmoApi.put(config.BACKEND_URL + `/api/villes/${id}`, data)
      .then((res) => {
        updateVille(id, res.data);
        addToast("Sauvegarde effectue", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.message });
        addToast(err.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const [name, setName] = useState(ville.name);
  return (
    <div className="ajouter-bien">
      <form>
        <div className="form-group">
          <label htmlFor="titre">Titre</label>
          <input
            type="text"
            name="titre"
            className="form-control"
            id="titre"
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>

        <div>
          <button className="btn btn-primary" onClick={() => history.back()}>
            ANNULER
          </button>
          <button
            className="btn btn-success"
            onClick={(e) => saveUpdate(e, ville.id, { name: name })}
          >
            ENREGISTER
          </button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateVille: (id, data) => dispatch(updateVille(id, data)),
  };
};
const mapStateToProps = (state, ownProps) => {
  const villes = getVilles(state);
  console.log(state, villes);
  const ville = getVilles(state).filter(
    (b) => b.id === parseInt(ownProps.match.params.id)
  )[0];
  return {
    ville: ville,
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditerVille));

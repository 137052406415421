import React from "react";
import axios from "axios";

import { connect } from "react-redux";
import config from "../../../config";
import { setCurrentUser } from "../../../redux/user/user.actions";
import { withRouter } from "react-router-dom";

import "./login.styles.scss";

class Login extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    if (this.props.currentUser) {
      this.props.history.replace("/admin/dashboard");
    }
    this.state = {
      email: "",
      password: "",
      isLoading: false,
    };
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }
  async handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    const url = `${config.BACKEND_URL}/api/auth/login`;

    this.setState({ isLoading: true });

    try {
      var resp = await axios.post(url, { email, password });
      var data = await resp.data;
      this.props.setCurrentUser(data);
      const { history } = this.props;
      history.push("/admin");
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }
  render() {
    const { email, password } = this.state;
    return (
      <section className="login-box">
        {this.state.isLoading ? (
          <div className="loading-page">
            <div className="sk-circle">
              <div className="sk-circle1 sk-child"></div>
              <div className="sk-circle2 sk-child"></div>
              <div className="sk-circle3 sk-child"></div>
              <div className="sk-circle4 sk-child"></div>
              <div className="sk-circle5 sk-child"></div>
              <div className="sk-circle6 sk-child"></div>
              <div className="sk-circle7 sk-child"></div>
              <div className="sk-circle8 sk-child"></div>
              <div className="sk-circle9 sk-child"></div>
              <div className="sk-circle10 sk-child"></div>
              <div className="sk-circle11 sk-child"></div>
              <div className="sk-circle12 sk-child"></div>
            </div>
          </div>
        ) : null}
        <div id="myModal_two">
          <div className="modal-dialog toggle_area" role="document">
            <div className="modal-header toggle_header">
              <h4 className="inner-title">Sign In Account</h4>
            </div>
            <div className="modal-body login_body">
              <p>Bienvenue , authentifiez vous pour continuer !</p>
              <div className="login_option">
                <form className="signin" action="#" method="post">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      value={email}
                      name="email"
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      name="password"
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      name="signin"
                      className="btn btn-default"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser: currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

import React from "react";

class AjouterBien extends React.Component {
  constructor() {
    super();
    this.state = {
      titre: "",
      description: "",
      prix: null,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <div className="ajouter-bien">
        <form>
          <div className="form-group">
            <label htmlFor="titre">Titre</label>
            <input
              type="text"
              name="titre"
              className="form-control"
              id="titre"
              required
              onChange={(e) => this.handleChange(e)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              className="form-control"
              id="description"
              required
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default AjouterBien;

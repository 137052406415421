import React from "react";
import Slider from "react-slick";

class HomeSlider extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Slider {...settings}>
        <div className="slide-item">
          <img src="img/slider/4.png" alt="" />
          <div className="carousel-caption">
            <div className="container">
              <div className="slider_text">
                <h1>Find Your Dream House</h1>
                <p>
                  Aliquet dignissim orci dignissim vehicula phasellus Erat etiam
                  posuere felis donec augue dapibus. Congue tempus pede urna
                  augue vivamus consectetuer. Rutrum. Litora vulputate Nec vel
                  ac penatibus hendrerit sociis purus, nec rhoncus ac vel.
                </p>
                <div className="slider_btn">
                  <a href="#" className="btn btn-default">
                    Read More
                  </a>
                  <a href="#" className="btn btn-primary">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src="img/slider/4.png" alt="" />
          <div className="carousel-caption">
            <div className="container">
              <div className="slider_text">
                <h1>Find Your Dream House</h1>
                <p>
                  Aliquet dignissim orci dignissim vehicula phasellus Erat etiam
                  posuere felis donec augue dapibus. Congue tempus pede urna
                  augue vivamus consectetuer. Rutrum. Litora vulputate Nec vel
                  ac penatibus hendrerit sociis purus, nec rhoncus ac vel.
                </p>
                <div className="slider_btn">
                  <a href="#" className="btn btn-default">
                    Read More
                  </a>
                  <a href="#" className="btn btn-primary">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default HomeSlider;

import {
    createSelector
} from 'reselect'

const biens = state => state.biens

const getBiens = createSelector(biens, biens => biens.biens);


export default getBiens;

import {
    SET_ALL_VILLE,
    DEL_VILLE,
    ADD_VILLE,
    UPDATE_VILLE
} from "./villes.types"

const INITIAL_STATE = {
    villes: [],
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ALL_VILLE:
            return {
                ...state, villes: action.payload
            };
        case ADD_VILLE:
            return {
                ...state, villes: state.villes.push(action.payload)
            };

        case DEL_VILLE:
            return {
                ...state,
                villes: state.villes.filter(v => v.id !== action.payload)
            };
        case UPDATE_VILLE:
            return {
                ...state,
                villes: [...state.villes.map(ville => {
                    return ville.id === parseInt(action.payload.id) ? action.payload.data : ville;
                })]
            };


        default:
            return state;
    }
}

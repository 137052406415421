import {
    SET_ALL_VILLE,
    ADD_VILLE,
    DEL_VILLE,
    UPDATE_VILLE
} from "./villes.types"


export const setAllVille = (villes) => ({
    type: SET_ALL_VILLE,
    payload: villes

})

export const addVille = (ville) => ({
    type: ADD_VILLE,
    payload: ville

})
export const deleteVille = (id) => ({
    type: DEL_VILLE,
    payload: id

})

export const updateVille = (id, data) => ({
    type: UPDATE_VILLE,
    payload: {
        id,
        data
    }

})








// export const deleteVille = (id) => {
//     return (dispatch) => {
//         dispatch(delVilleStarted());
//         ImmoApi
//             .delete(config.BACKEND_URL + `/api/villes/${id}`).then(res => {
//                 dispatch(delVilleSuccess(id));
//             })
//             .catch(err => {
//                 dispatch(delVilleFailure(err.message));
//             });
//     }

// }

// const delVilleSuccess = (id) => ({
//     type: DEL_VILLE,
//     payload: id

// })

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { setCurrentUser } from "../../redux/user/user.actions";
import getCurrentUser from "../../redux/user/user.selectors";

const Header = ({ currentUser, setCurrentUser }) => (
  <header id="header">
    <div id="top_header">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-5">
            <div className="top_contact">
              <ul>
                <li>
                  <i className="fa fa-phone" aria-hidden="true"></i> Need
                  Support ? +1-435-782-4312
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-7">
            <div className="top_right">
              <ul>
                <li>
                  <div className="lan-drop">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Eng <span className="caret"></span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="#">sp</a>
                      </li>
                      <li>
                        <a href="#">ch</a>
                      </li>
                      <li>
                        <a href="#">ud</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="signup.html" className="toogle_btn">
                    Register
                  </a>
                </li>
                <li>
                  {currentUser ? (
                    <button
                      onClick={() => setCurrentUser(null)}
                      className="toogle_btn"
                    >
                      Logout
                    </button>
                  ) : (
                    <Link to="/admin/login">Login</Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="nav_header">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <nav className="navbar navbar-default nav_edit">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href="#">
                  <img className="nav-logo" src="img/logo1.png" alt="" />
                </a>
              </div>
              <div
                className="collapse navbar-collapse my_nav"
                id="bs-example-navbar-collapse-1"
              >
                <div className="submit_property">
                  <a href="submit_property.html">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    Submit Property
                  </a>
                </div>
                <ul className="nav navbar-nav navbar-right nav_text">
                  <li className="dropdown">
                    <a
                      href="index_1.html"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Home{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="active">
                        <a href="index_1.html">Simple Image Slider</a>
                      </li>
                      <li>
                        <a href="index_2.html">Fixed Height Map</a>
                      </li>
                      <li>
                        <a href="index_3.html">Video Banner + Search</a>
                      </li>
                      <li>
                        <a href="index_4.html">Fixed Banner + Search</a>
                      </li>
                      <li>
                        <a href="index_5.html">Property Slide</a>
                      </li>
                      <li>
                        <a href="index_6.html">Full Height Map + Nav</a>
                      </li>
                      <li>
                        <a href="index_7.html">Fixed Height Map + Search</a>
                      </li>
                      <li>
                        <a href="index_8.html">Full Height Map + Search</a>
                      </li>
                      <li>
                        <a href="index_9.html">Map Left + Search Right</a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Properties{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Property List{" "}
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="property_list.html">Right Sidebar</a>
                          </li>
                          <li>
                            <a href="property_list_left.html">Left Sidebar</a>
                          </li>
                          <li>
                            <a href="property_list_full.html">Full Width</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Property Grid{" "}
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="property_grid.html">Right Sidebar</a>
                          </li>
                          <li>
                            <a href="property_grid_left.html">Left Sidebar</a>
                          </li>
                          <li>
                            <a href="property_grid_full.html">Full Width</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Single Properties{" "}
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="single_property.html">Single Property 1</a>
                          </li>
                          <li>
                            <a href="single_property_2.html">
                              Single Property 2
                            </a>
                          </li>
                          <li>
                            <a href="single_property_3.html">
                              Single Property 3
                            </a>
                          </li>
                          <li>
                            <a href="single_property_4.html">
                              Single Property 4
                            </a>
                          </li>
                          <li>
                            <a href="single_property_5.html">
                              Single Property 5
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Property With Map{" "}
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="property_grid_map.html">Grid View</a>
                          </li>
                          <li>
                            <a href="property_list_map.html">List View</a>
                          </li>
                          <li>
                            <a href="property_grid_fullmap.html">
                              Grid View Full Map
                            </a>
                          </li>
                          <li>
                            <a href="property_list_fullmap.html">
                              List View Full Map
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Agents{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="agents.html">Agents</a>
                      </li>
                      <li>
                        <a href="agent_profile_grid.html">Agent Profile Grid</a>
                      </li>
                      <li>
                        <a href="agent_profile_list.html">Agent Profile List</a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Pages{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          About{" "}
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="about.html">About Us</a>
                          </li>
                          <li>
                            <a href="mission.html">Our Mission</a>
                          </li>
                          <li>
                            <a href="career.html">Careers</a>
                          </li>
                          <li>
                            <a href="award.html">Awards</a>
                          </li>
                          <li>
                            <a href="testimonial.html">Testimonials</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          My Account{" "}
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="my_profile.html">My Profile</a>
                          </li>
                          <li>
                            <a href="profile_media.html">Social Media</a>
                          </li>
                          <li>
                            <a href="my_properties.html">My Properties</a>
                          </li>
                          <li>
                            <a href="my_favorite.html">Favorited Properties</a>
                          </li>
                          <li>
                            <a href="submit_property.html">
                              Submit New Property
                            </a>
                          </li>
                          <li>
                            <a href="message.html">Message</a>
                          </li>
                          <li>
                            <a href="comments.html">Feedback and Comments</a>
                          </li>
                          <li>
                            <a href="invoices.html">Payments and Invoice</a>
                          </li>
                          <li>
                            <a href="change_password.html">Change Password</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="our_service.html">Our Service</a>
                      </li>
                      <li>
                        <a href="submit_property.html">Submit Property</a>
                      </li>
                      <li>
                        <a href="terms_and_condition.html">
                          Terms And Condition
                        </a>
                      </li>
                      <li>
                        <a href="pricing_table.html">Pricing Table</a>
                      </li>
                      <li>
                        <a href="invoice_details.html">Invoice</a>
                      </li>
                      <li>
                        <a href="message_view.html">Message</a>
                      </li>
                      <li>
                        <a href="error.html">Error Page</a>
                      </li>
                      <li>
                        <a href="faq.html">FAQ</a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Blog{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="blog_grid.html">Blog Grid</a>
                      </li>
                      <li>
                        <a href="blog.html">Blog List</a>
                      </li>
                      <li>
                        <a href="blog_detail.html">Blog Detail</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
);

function mapStateToProps(state) {
  return getCurrentUser(state);
}
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

import React, { Component } from "react";

import HomeSlider from "../../components/home-slider/home-slider.component";

import "./homepage.styles.scss";

class HomePage extends Component {
  render() {
    return (
      <div className="home-page">
        <HomeSlider />
        <div id="find_area" style={{ marginTop: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="property_search_form">
                  <form
                    action="#"
                    method="post"
                    className="property_filter_input"
                  >
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <select className="selectpicker form-control">
                          <option>Any Status</option>
                          <option>For Rent</option>
                          <option>For Sale</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <select className="selectpicker form-control">
                          <option>Any State</option>
                          <option>New york</option>
                          <option>Sydney</option>
                          <option>Washington</option>
                          <option>Las vegas</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <select className="selectpicker form-control">
                          <option>Bedrooms</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>6</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <select className="selectpicker form-control">
                          <option>Bathrooms</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <select className="selectpicker form-control">
                          <option>Any Type</option>
                          <option>House</option>
                          <option>Office</option>
                          <option>Appartment</option>
                          <option>Condos</option>
                          <option>Villa</option>
                          <option>Small Family</option>
                          <option>Single Room</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="minprice"
                          placeholder="Min Price(USD)"
                        />
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="maxprice"
                          placeholder="Max Price(USD)"
                        />
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <input
                          type="submit"
                          name="search"
                          className="btn btn-default"
                          value="Search"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;

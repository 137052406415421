import React from "react";
import { Link } from "react-router-dom";

import "./sidebar.styles.scss";

const SideBar = () => (
  <div className="sidebar">
    <Link to="/admin/biens" className="sidebar-item">
      Biens
    </Link>
    <Link to="/admin/categorie-biens" className="sidebar-item">
      Categories de Biens
    </Link>
    <Link to="/admin/villes" className="sidebar-item">
      Villes
    </Link>
  </div>
);

export default SideBar;

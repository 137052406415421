import React from "react";

import { connect } from "react-redux";

import { addVille } from "../../../redux/villes/villes.actions";

import ImmoApi from "../../../Api";
import config from "../../../config";

import "./ajouter-ville.styles.scss";
import { useToasts } from "react-toast-notifications";

class AjouterVille extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.addVille(this.state);
  };

  addVille(data) {
    console.log(data);
    ImmoApi.post(config.BACKEND_URL + "/api/villes", data)
      .then((res) => {
        this.props.addVille(res.data);
        this.setState({ name: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="ajouter-ville">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              name="name"
              className="form-control"
              id="name"
              required
              value={this.state.name}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
          <div className="form-group">
            <button>Ajouter</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addVille: (data) => dispatch(addVille(data)),
  };
};

export default connect(null, mapDispatchToProps)(AjouterVille);

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import getBiens from "../../../redux/biens/biens.selectors";
import "./biens-list.styles.scss";
import { setAllBiens } from "../../../redux/biens/biens.actions";
import ImmoApi from "../../../Api";
import config from "../../../config";

class ListBiens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }
  componentDidMount() {
      this.loadBiens();
  }

  loadBiens = () => {
    this.setState({ loading: true });
    ImmoApi.get(config.BACKEND_URL + "/api/biens")
      .then((resp) => {
        this.props.setAllBiens(resp.data.data);
      })
      .catch((err) => {
        this.setState({ error: err.message });
      });
  };

  handleDelete(id) {}
  render() {
    const { biens, history, match } = this.props;
    return (
      <div className="biens-list">
        <div className="action-bar">
          <button
            className="button "
            onClick={() => history.push(match.url + "/ajouter")}
          >
            Ajouter <i className="fa fa-add"></i>
          </button>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">Titre</th>
              <th scope="col">Description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {biens.map((bien) => (
              <tr key={bien.id}>
                <td>{bien.id}</td>
                <td>{bien.titre}</td>
                <td>{bien.description}</td>
                <td>
                  <div className="actions-btn">
                    <button
                      className="btn btn-primary "
                      onClick={() =>
                        history.push(match.url + `/${bien.id}/editer`)
                      }
                    >
                      Editer <i className="fa fa-add"></i>
                    </button>

                    <button
                      className="btn btn-small btn-danger "
                      onClick={() => this.handleDelete(bien.id)}
                    >
                      Supprimer <i className="fa fa-add"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
function mapStateToProps(state) {
  console.log(getBiens(state));
  return {
    biens: getBiens(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAllBiens: (biens) => dispatch(setAllBiens(biens)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListBiens));

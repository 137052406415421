import axios from "axios";
import config from './config';
import history from "./utils/history";

import {
    setCurrentUser
} from './redux/user/user.actions';


const ImmoApi = axios.create({
    baseURL: config.BACKEND_URL
});

ImmoApi.interceptors.request.use((config) => {
    console.log('REQUEST SENT');
    const user = localStorage.getItem('user');
    if (user != null) {
        const accessToken = JSON.parse(user).access_token;
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}, error => {
    Promise.reject(error)
});

ImmoApi.interceptors.response.use((response) => {
        return response
    },
    function (error) {
        const originalRequest = error.config;
        console.log(originalRequest);
        if (originalRequest.url === "/api/auth/refresh") {
            localStorage.removeItem('user')
            setCurrentUser(null);
            history.push('/admin/login');
            Promise.reject(error);
            return;
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return ImmoApi.post('/api/auth/refresh', )
                .then(res => {
                    if (res.status === 201) {
                        // 1) put token to LocalStorage
                        localStorage.setItem('user', JSON.stringify(res.data));

                        // 2) Change Authorization header
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;

                        // 3) return originalRequest object with Axios.
                        return axios(originalRequest);
                    }
                })
        }
    });



export default ImmoApi;

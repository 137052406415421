import {
    ADD_BIEN,
    SET_ALL_BIENS
} from "./biens.types"

const INITIAL_STATE = {
    biens: []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ADD_BIEN:
            return {
                ...state,
                loading: false,
                    biens: [...state.biens, action.payload]
            };
        case SET_ALL_BIENS:
            return {
                ...state,
                loading: false,
                    biens: action.payload
            };
        default:
            return state;
    }
}

import React from "react";
import { Switch, Route } from "react-router-dom";

import AdminRoutes from "./admin/admin";

import Homepage from "./pages/homepage/homepage.component";
import Header from "./components/header/header.component";

import "./App.css";
import { setCurrentUser } from "./redux/user/user.actions";
import { connect } from "react-redux";

class App extends React.Component {
  constructor(props) {
    super(props);
    const { setCurrentUser } = this.props;
    const user = localStorage.getItem("user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
    }
  }
  render() {
    return (
      <div className="pagewrap">
        <Header />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/admin" component={AdminRoutes} />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToState = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(null, mapDispatchToState)(App);

import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import SideBar from "../../components/sidebar/sidebar.component";
import ListBiens from "../../components/biens-list/biens-list.component";
import EditerBien from "../../components/editer-bien/editer-bien.component";
import ListVilles from "../../components/villes-list/villes-list.component";
import ListCategories from "../../components/categories-biens/categories-biens.component";

import "./dashboard.styles.scss";
import AjouterBien from "../../components/ajouter-bien/ajouter-bien.component";
import ajouterVille from "../../components/ajouter-ville/ajouter-ville.component";
import EditerVille from "../../components/editer-ville/editer-ville.component";
import AjouterCat from "../../components/ajouter-cat/ajouter-cat.component";
import EditerCat from "../../components/editer-cat/editer-cat.component";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.currentUser) {
      this.props.history.push("/admin/login");
    }
  }
  render() {
    return (
      <div className="dashboard">
        <SideBar class="sidebar" />
        <div className="admin-content">
          <Switch>
            <Route
              exact
              path={this.props.match.url + "/biens"}
              component={ListBiens}
            />
            <Route
              exact
              path={this.props.match.url + "/biens/:id/editer"}
              component={EditerBien}
            />
            <Route exact path={this.props.match.url} component={ListBiens} />
            <Route
              exact
              path={this.props.match.url + `/biens/ajouter`}
              component={AjouterBien}
            />
            <Route
              exact
              path={this.props.match.url + `/categorie-biens`}
              component={ListCategories}
            />
            <Route
              exact
              path={this.props.match.url + `/categorie-biens/ajouter`}
              component={AjouterCat}
            />

            <Route
              exact
              path={this.props.match.url + "/villes"}
              component={ListVilles}
            />
            <Route
              exact
              path={this.props.match.url + "/villes/ajouter"}
              component={ajouterVille}
            />

            <Route
              exact
              path={this.props.match.url + "/villes/:id/editer"}
              component={EditerVille}
            />
            <Route
              exact
              path={this.props.match.url + "/categorie-biens/:id/editer"}
              component={EditerCat}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
function mapStateToProps({ user: { currentUser } }) {
  return { currentUser };
}
export default connect(mapStateToProps)(withRouter(Dashboard));

import {
    combineReducers
} from 'redux';

import user from './user/user.reducer';
import biens from './biens/biens.reducer';
import villes from './villes/villes.reducer';

const reducers = {
    user,
    biens,
    villes
};

export default combineReducers(
    reducers
);

import React, { useState, useEffect } from "react";

import { useToasts } from "react-toast-notifications";

import { withRouter } from "react-router-dom";
import ImmoApi from "../../../Api";
import config from "../../../config";
import history from "../../../utils/history";

const EditerCat = ({ match }) => {
  const [name, setName] = useState("");
  const [cat, setCat] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    ImmoApi.get(config.BACKEND_URL + `/api/types/${match.params.id}`)
      .then((res) => {
        setCat(res.data);
        setName(res.data.name);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.message });
        addToast(err.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  }, [addToast, match.params.id]);
  const saveUpdate = (e, id, data) => {
    e.preventDefault();
    ImmoApi.put(config.BACKEND_URL + `/api/types/${id}`, data)
      .then((res) => {
        addToast("Sauvegarde effectue", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.message });
        addToast(err.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div className="ajouter-bien">
      <form>
        <div className="form-group">
          <label htmlFor="titre">Titre</label>
          <input
            type="text"
            name="titre"
            className="form-control"
            id="titre"
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>

        <div>
          <button className="btn btn-primary" onClick={() => history.back()}>
            ANNULER
          </button>
          <button
            className="btn btn-success"
            onClick={(e) => saveUpdate(e, cat.id, { name: name })}
          >
            ENREGISTER
          </button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(EditerCat);

import React, { useEffect } from "react";

import { connect } from "react-redux";

import { Route, Switch } from "react-router-dom";
import Login from "./pages/login/login.component";
import Dashboard from "./pages/dashboard/dashboard.component";

function AdminRoutes({ match, currentUser, loadBiens, loadVilles }) {
  return (
    <div>
      {/* note the addition of the exact property here */}

      <Switch>
        <Route
          exact
          path={`${match.url}/login`}
          render={() => (currentUser ? <Dashboard /> : <Login />)}
        />
        <Route path={match.url} component={Dashboard} />
      </Switch>
    </div>
  );
}

const mapStateToProps = ({ currentUser }) => {
  return { currentUser };
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoutes);

import React from "react";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import ReactDOM from "react-dom";

import { Router } from "react-router-dom";
import history from "./utils/history";

import store from "./redux/store";
import App from "./App";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
